import { TradingPlan } from '../../../interfaces/User';

interface PlanMobileProps {
	getTitle: (
		plan: TradingPlan
	) => 'Request Upgrade' | 'Request Plan' | 'Nil' | 'Active' | 'Requested';
	onPlanClick: (
		plan: TradingPlan,
		title: 'Request Upgrade' | 'Request Plan'
	) => void;
}

const PlanMobile: React.FC<PlanMobileProps> = ({ getTitle, onPlanClick }) => {
	return (
		<div className='bv-plan-mobile py-3'>
			<div className='blog-wrapper'>
				<div className='container'>
					<div className='row g-3'>
						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Newbie</u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$3,000.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong></strong> Trades/Day
									</p>
									<p>
										<strong>ATS</strong>
									</p>
									<br />
									<br />
									<button
										className='btn btn-bold btn-block btn-outline btn-dark'
										onClick={() => {
											const title = getTitle('Newbie-3000');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Newbie-3000', title);
											}
										}}
										disabled={getTitle('Newbie-3000') === 'Nil'}
									>
										{getTitle('Newbie-3000')}
									</button>
								</div>
							</div>
						</div>

						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Beginner </u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$7,600.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong>5</strong> Trades/Day
									</p>
									<p>
										<strong>ATS</strong>
									</p>

									<br />
									<br />
									<button
										className='btn btn-bold btn-block btn-outline btn-dark'
										onClick={() => {
											const title = getTitle('Beginner-7600');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Beginner-7600', title);
											}
										}}
										disabled={getTitle('Beginner-7600') === 'Nil'}
									>
										{getTitle('Beginner-7600')}
									</button>
								</div>
							</div>
						</div>

						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Experienced </u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$21,199.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong>10</strong> Trades/Day
									</p>
									<p>
										<strong>ATS</strong>
									</p>

									<br />
									<br />
									<button
										className='btn btn-primary btn-sm'
										onClick={() => {
											const title = getTitle('Experienced-21199');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Experienced-21199', title);
											}
										}}
										disabled={getTitle('Experienced-21199') === 'Nil'}
									>
										{getTitle('Experienced-21199')}
									</button>
								</div>
							</div>
						</div>

						<div className='col-6 col-sm-4 col-md-3'>
							<div className='card position-relative shadow-sm'>
								<center>
									<h3 className='text-uppercase'>
										<u>Master </u>
									</h3>
									<h4>
										<span className='badge bg-danger rounded-pill mb-2 d-inline-block'>
											$50,730.00
										</span>
									</h4>
									<h4></h4>
								</center>

								<div className='card-body'>
									<p>
										<strong>14</strong> Trades/Day
									</p>
									<p>
										<strong>ATS (VIP)</strong>
									</p>
									<br />
									<br />
									<button
										className='btn btn-primary btn-sm'
										onClick={() => {
											const title = getTitle('Master-50730');

											if (
												title === 'Request Plan' ||
												title === 'Request Upgrade'
											) {
												onPlanClick('Master-50730', title);
											}
										}}
										disabled={getTitle('Master-50730') === 'Nil'}
									>
										{getTitle('Master-50730')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlanMobile;
