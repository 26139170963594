import React from 'react';
import btcImg from '../../../assets/images/btc.jpg';

interface WithdrawFundMobileProps {
	openModal: VoidFunction;
}

const WithdrawFundMobile: React.FC<WithdrawFundMobileProps> = ({
	openModal,
}) => {
	return (
		<div className='bv-withdraw-fund-mobile py-3'>
			<div className='container'>
				<div className='card'>
					<div className='card-body'>
						<center>
							<h2> Withdrawal Methods </h2>
							<a className='btn btn-dark' onClick={openModal}>
								<img src={btcImg} alt='Withdrawal Methods' />
							</a>
						</center>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WithdrawFundMobile;
