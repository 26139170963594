import React from "react";
// import logo from "../../../assets/images/logo.png";
import main from '../../../assets/images/main.png';
import laptopBg from '../../../assets/images/laptop-bg.png';
import crpGif from '../../../assets/images/cryp.gif';

import dgtImg from '../../../assets/images/dgt.png';
import dgt2Img from '../../../assets/images/dgt2.png';
import dgt3Img from '../../../assets/images/dgt3.png';
import dgt4Img from '../../../assets/images/dgt4.png';

import why1Img from '../../../assets/images/why1.png';
import why2Img from '../../../assets/images/why2.png';
import why3Img from '../../../assets/images/why3.png';
import why4Img from '../../../assets/images/why4.png';
import why5Img from '../../../assets/images/why5.png';
import why6Img from '../../../assets/images/why6.png';
import why7Img from '../../../assets/images/why7.png';
import why8Img from '../../../assets/images/why8.png';

const Home: React.FC = () => {

    return (
        <div className="bv-home-page page">
            <div className="bv-home-page__welcome-area-start">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-md-6">
                            <div className="bv-home-page__welcome-area-start__welcome-content">
                                <h3>
                                    Trade With <br />
                                    <span>Confidence</span>
                                </h3>

                                <p>
                                    The Planet&apos;s Best Trading Platform.Join millions who are winners and get mind blowing profits from our minimal risk trading system.
                                </p>

                                <div className="btn-area">
                                    <a className="btn btn-box" onClick={() => {}}>Trade Now</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div>
                                <div className="text-center">
                                    <div>
                                        <img src={main} height="500" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bv-home-page__wallet-area section-padding-100-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                        
                            <h2 className="heading-title">Experience more than Trading.</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="800">
                            <div className="single-wallet-area two">
                            
                                <div className="wallet-content-text">
                                
                                    <p>Wide Range of Trading Instruments</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                            <div className="single-wallet-area two">
                            
                                <div className="wallet-content-text">
                                
                                    <p>Unparalleled Trading Conditions.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                            <div className="single-wallet-area two">
                            

                                <div className="wallet-content-text">
                                
                                    <p>Globally Licensed &amp; Regulated.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400">
                            <div className="single-wallet-area two">
                            
                                <div className="wallet-content-text">
                                
                                    <p>Committed to Forex Education.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="bv-home-page__about-us-area bg-color-cu section-padding-100-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="about-image-area text-center mb-50">
                                <div className="about-feature-img welcome-thumb">
                                    <img src={crpGif} alt="" />
                                </div>
                                <div className="about-image-2">
                                    <img src={laptopBg} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="about-content-text pb-50">
                                <h2>Innovative Trading platform</h2>

                                <p className="mb-0">Profit can be greatly expanded
                                with less capital. You can take advantage of both rising and falling cryptocurrencies. 
                                The cryptocurrency market is open all day, every day.</p>
                                <div className="btn-area mt-50">
                                    <a className="btn btn-box" href="https://caryatid.live/login">Trade Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bv-home-page__our-team-area section-padding-100-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="heading-title">Experience more than Trading.</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={dgtImg} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Convenient trading interface</h4>
                                    <h6>We created the most simple and comfortable interface that does not distract from the main thing - from trading</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={dgt2Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Integrated signals</h4>
                                    <h6>Approach the strategy thoughtfully - the most precise and innovative signals with an accuracy of 87% will help you create your own effective strategy</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={dgt3Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Trading indicators</h4>
                                    <h6>We have gathered the most useful trading indicators. Use them to boost your account balance</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={dgt4Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Perfect speed</h4>
                                    <h6>Our platform runs on the most modern technology and delivers incredible speed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bv-home-page__our-team-area  bg-color-cu section-padding-100-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="heading-title">WHY Caryatid.</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why1Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>FLEXIBLE TRADING</h4>
                                    <h6>Latest trends: quick and digital trading, express trades, mt5 forex, pending orders, trades copying. Payouts up to 218%.</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why2Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Comprehensive Education</h4>
                                    <h6>Our help section contains tutorials, guides and various trading strategies</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why3Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Diverse Trading Instruments</h4>
                                    <h6>Assets suitable for any trader: currency, commodities, stocks, cryptocurrencies.</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why4Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Demo Account</h4>
                                    <h6>Try all platform benefits on the Demo account using virtual money. No investment needed, no risks involved</h6>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why5Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Easy Deposits and Withdrawals</h4>
                                    <h6>Use the most convenient payment method for hassle-free deposits and withdrawals.</h6>
                                </div>
                            </div>
                        </div>
                                            
                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why6Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>High Customer Loyalty</h4>
                                    <h6>Trading tournaments, regular bonuses, gifts, promo codes and contests are available to any trader.</h6>
                                </div>
                            </div>
                        </div>
                                              
                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why7Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Trading Advantages</h4>
                                    <h6>Use cashback and other advantages for a more comfortable trading experience with minimal risks.</h6>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="col-sm-6 col-lg-3">
                            <div className="single-team-area team-2 text-center">
                                <div className="">
                                    <img src={why8Img} alt="Image" />
                                </div>

                                <div className="team-content-text text-center">
                                    <h4>Indicators and Signals</h4>
                                    <h6>Everything you need for a top-tier trading experience including popular indicators and signals.</h6>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Home };